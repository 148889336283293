import React from "react"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import DynamicSlider from "../components/DynamicSlider"
import ComponentSelector from "../components/ComponentSelector"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import ShareWidget from "../components/ShareWidget"

export default function Update({ data, pageContext, location }) {
  // = = = = = = = = = = = = =
  // Data
  const { slug, title } = pageContext
  const { host, pathname } = location
  const { components } = data.update.nodes[0]

  const {
    id,
    authors,
    metadata,
    heroImage,
    introText,
    datePosted,
    thematicTags,
    featuredImage,
    relatedProjects,
    relatedFocusAreas,
  } = data.update.nodes[0]

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;


  // = = = = = = = = = = = = =
  // Slider Props
  const sliderProps = {
    limit: 6,
    skip: id,
    cardColor: "#655DC6",
    cardAlignment: "horizontal",
    headline: "<span>Related</span> Updates",
    link: [
      {
        label: "All updates",
        linkUrl: "/updates",
        isThisAButton: false,
      },
    ],
    relatedProjects: relatedProjects,
    relatedFocusAreas: relatedFocusAreas,
    contentType: [
      {
        contentType: "update",
      },
    ],
    background: [
      {
        hasBackgroundImage: true,
      },
    ],
  }

  const thematicTagsArray = thematicTags.split(", ")

  const updatesTagsArray = [
    {
      tagLabel: "thematicTags",
      tags: [...thematicTagsArray],
    },
    {
      tagLabel: "relatedProjects",
      tags: relatedProjects.map(project => project.title),
    },
    {
      tagLabel: "relatedFocusAreas",
      tags: relatedFocusAreas.map(focusArea => focusArea.title),
    },
  ]

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />

        <div className="update">
          <section className="update__header">
            <div className="container">
              <div data-datocms-noindex className="row">
                <div className="col-md-12">
                  <Link className="update__back-link" to="/updates">
                    ‹ Updates
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <h1 className="update__headline">{title}</h1>
                  <p className="update__article-date">{datePosted}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="update__main-image">
                    <GatsbyImage image={getImage(heroImage)} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="update__introduction">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p>{introText}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="update__content">
            <ShareWidget host={host} pathname={pathname} title={title} />
            <div className="container">
              <div className="row">
                <div className="col-md-11 offset-md-1">
                  <div className="update__article-data">
                    {authors.length > 0 && (
                      <p className="update__article-authors">
                        <span>By </span>
                        {authors.map((author, i) => {
                          if (
                            i + 1 < authors.length &&
                            i + 1 !== authors.length - 1
                          ) {
                            return (
                              <span key={author.name}>
                                <a href={author.link} target="_blank" rel="noreferrer">
                                  {author.name}
                                </a>
                                ,{" "}
                              </span>
                            )
                          } else if (i + 1 === authors.length - 1) {
                            return (
                              <span key={author.name}>
                                <a href={author.link} target="_blank" rel="noreferrer">
                                  {author.name}
                                </a>{" "}
                                and{" "}
                              </span>
                            )
                          } else {
                            return (
                              <span key={author.name}>
                                <a href={author.link} target="_blank" rel="noreferrer">
                                  {author.name}
                                </a>
                              </span>
                            )
                          }
                        })}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <ComponentSelector components={components} />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </section>

          {updatesTagsArray
            .map(tagsObject => tagsObject.tags)
            .flat()
            .toString().length > 0 && (
            <section className="update__tags">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="content-wrapper">
                      <h5>Tagged</h5>
                      <ul className="tags-list">
                        {updatesTagsArray.map(tagObject =>
                          tagObject.tags?.map(
                            tag =>
                              // Generate li just if tag has value
                              tag && (
                                <li className="update__tag" key={tag}>
                                  <Link
                                    to={`/updates?${
                                      tagObject?.tagLabel
                                    }=${tag.replaceAll(" ", "_")}`}
                                  >
                                    {tag}
                                  </Link>
                                </li>
                              )
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>

        {(relatedProjects || relatedFocusAreas) && (
          <DynamicSlider component={sliderProps} />
        )}

        <Footer showFooterForm={true} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    update: allDatoCmsUpdate(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        datePosted(formatString: "MMMM DD, YYYY")
        thematicTags
        authors {
          name
          link
        }
        structuralTags
        thematicTags
        relatedFocusAreas {
          title
        }
        relatedProjects {
          title
        }
        introText
        heroImage {
          gatsbyImageData
          alt
          title
        }
        featuredImage {
          gatsbyImageData
          alt
          title
        }
        components {
          ... on DatoCmsTextContent {
            componentId
            content
          }
          ... on DatoCmsArticleImage {
            componentId
            image {
              gatsbyImageData
            }
          }
          ... on DatoCmsSocialMediaEmbed {
            componentId
            smEmbed
          }
          ... on DatoCmsInnerPostSignupForm {
            everyActionFormLink
            componentId
          }
          ... on DatoCmsVideoEmbed {
            componentId
            embedCode
            youtubeOrVimeo
            imageOverlay
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsInnerTextProjectCard {
            componentId
            description
            cardLink {
              label
              linkUrl
            }
            headline
            tag
          }
        }
      }
    }
  }
`
